export default {
    menu:{
        m0:'Inicio',
        m1:'Servicios',
        m2:'Proyectos',
        m3:'Contacto',
        m4:'Lenguaje'
    },
    title:"Frenzy Games Std",
    subtitles:{
        s1:'Trabajo profesional, servicio confiable',
        s2:'Tu proyecto al alcance de todos',
        s3:'Un vistazo a las posibilidades',
        s4:'¿Cuál es tu idea?'
    },
    description:'Con más de 8 años de experiencias, sabemos que necesitas soluciones novedosas y eficientes. Tu proyecto es importante, no te conformes con sistemas frágiles o inseguros. Obtén aquello que te de tranquilidad, no más problemas.',
    services:{
        s1:'Web',
        s2:'Interactivos',
        s3:'Móviles',
        sd1:'Aplicaciones web que se adaptan a cualquier dispositivo. Sin necesidad de tantos cambios o recargas de página, olvidaras que estas usando un explorador. Eficiente, confiable y rápido.',
        sd2:'Una idea creativa siempre hace la diferencia. Sorprende a tus usuarios y clientes con algo que realmente los marque. Juegos, aplicaciones interactivas y realidad virtual son tus mejores opciones.',
        sd3:'Tus servicios a la mano de cualquier persona, sin importar cuando o donde. Una aplicación móvil duplica las posibilidades y el alcance de tu proyecto. Al final ¿Quién no hace uso de un celular?'
    },
    projects:{
        p1:'Página informativa',
        p2:'Videojuego móvil',
        p3:'App de restaurante',
        p4:'Realidad virtual'
    },
    contactform:{
        title:'Hablemos de tu proyecto, envía un mensaje resumido lo que buscas construir.',
        t1:'Nombre:',
        t2:'Correo:',
        t3:'Mensaje:',
        sc:'Tu correo no será compartido con nadie',
        sl:'lineamientos de proyecto',
        send:'Enviar',
        sended:'Tu mensaje se enviará desde tu aplicación de correo preferida.',
        success:'Recibirás una respuesta pronto, mantente atento a tu correo.'
    }
}