import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Translate from 'react-translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faStar, faCode, faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Navbarmenu(props)
{
    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="fixed-top">
        <Navbar.Brand href="#home"><FontAwesomeIcon icon={faHome}/>
        <span>&nbsp;</span><Translate content="menu.m0"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link href="#services"><FontAwesomeIcon icon={faStar}/>
            <span>&nbsp;</span><Translate content="menu.m1"/></Nav.Link>
            <Nav.Link href="#projects"><FontAwesomeIcon icon={faCode}/>
            <span>&nbsp;</span><Translate content="menu.m2"/></Nav.Link>
            <Nav.Link href="#contact"><FontAwesomeIcon icon={faEnvelope}/>
            <span>&nbsp;</span><Translate content="menu.m3"/></Nav.Link>
            
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    )
}

/*
function Langitem(props)
{
    return(
        <NavDropdown.Item onClick={props.onClick}>{props.text}</NavDropdown.Item>
    )    
} 

<NavDropdown title={props.langtext} id="collasible-nav-dropdown">
    <Langitem text='Español' onClick={() => props.onClick('es')}/>
    <Langitem text='English' onClick={() => props.onClick('en')}/>
</NavDropdown>
*/

export default Navbarmenu;