import React from 'react';
//translates
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import es from './lang/es';
import en from './lang/en';
//css and animations
import WOW from 'wowjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
//images
import img_logo from './img/logo.png';
import img_bkheader from './img/header.jpg';
//Components
import Navbarmenu from './components/Navbarmenu';
import Services from './components/Services';
import Projects from './components/Projects'
import Contact from './components/Contact';

counterpart.registerTranslations('es',es);
counterpart.registerTranslations('en',en);

counterpart.setLocale('es');

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lang:'es'
    };
  }

  componentDidMount () {
    new WOW.WOW({
        live: false
    }).init();
  }

  ChangeLang(lang){
    this.setState({
      lang:lang
    });
    counterpart.setLocale(lang);
  }

  render(){
    const langtext = counterpart.translate('menu.m4'); 

    return (
    <main className="App" id="main">
      <Navbarmenu langtext={langtext} onClick={lang => this.ChangeLang(lang)}/>
      <Title text="title"/>
      <Subtitle text="subtitles.s1" delay="1s"/>
      <TextContent text='description' delay="2s"/>
      <Subtitle text="subtitles.s2"  delay="3s" myid="services"/>
      <Services/>
      <Subtitle text="subtitles.s3" myid="projects"/>
      <Projects/>
      <Subtitle text="subtitles.s4" myid="contact"/>
      <Contact/>
      <Footer/>
    </main>
    );
  }
}

function Title(props)
{
  return (
    <section id="home">
      <div className="container-fluid relative_container text-center pr2">
        <img src={img_bkheader} alt="backgr" className="w100 bk_header"/>
        <div className="centered">
          <div className="white wow fadeInUp ptnv">
          <Translate content={props.text} component="h1" className="bshadow"/>
          </div>
          <img src={img_logo} alt="logo" className="wow fadeIn"/>
        </div>
      </div>
    </section>
  )
}

function Subtitle(props)
{
  return (
    <section id={props.myid}>
      <div className="container text-center pr2">
        <div className="blue wow fadeIn" data-wow-duration="1s" data-wow-delay={props.delay}>
          <Translate content={props.text} component="h2"/>
        </div>
      </div>
    </section>
  )
}

function TextContent(props)
{
  return (
    <section>
      <div className="container text-center p8">
        <div className="white wow fadeIn" data-wow-duration="1s" data-wow-delay={props.delay}>
          <Translate content={props.text} component="p" className="fsp18"/>
        </div>
      </div>
    </section>
  )
}

function Footer()
{
  return(
    <section>
      <div className="container tc p20">
      <small className="white">Frenzy Games Std</small>
      </div>
    </section>
  )
}

export default App;
