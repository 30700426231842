import React from 'react';
import { faGlobe, faGamepad, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import Card from './Card';

function Services(props)
{
    return(
        <section>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-4 wow fadeInUp" data-wow-duration="1s" data-wow-offset="200">
                        <Card title="services.s1" text="services.sd1" icon={faGlobe}/>
                    </div>
                    <div className="col-md-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="200">
                        <Card title="services.s2" text="services.sd2" icon={faGamepad}/>
                    </div>
                    <div className="col-md-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="2s" data-wow-offset="200">
                        <Card title="services.s3" text="services.sd3" icon={faMobileAlt}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;