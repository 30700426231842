import React from 'react';
import Translate from 'react-translate-component';

function ProjectPreview(props)
{
    return(
        <div className="tc p16">
            <Translate content={props.title} component="p" className="mr1 fsp18 white title_card"/>
            <img src={props.image} alt="project preview" className="img_project"/>
        </div>
    );
}

export default ProjectPreview;