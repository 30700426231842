import React from 'react';
import Translate from 'react-translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Card(props)
{
    return (
        <div className="white tc pr2">
            <FontAwesomeIcon icon={props.icon} size="5x"/>
            <Translate content={props.title} component="p" className="mr1 fsp18 blue title_card"/>
            <Translate content={props.text} component="p"/>
        </div>
    );
}

export default Card;