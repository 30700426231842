import React from 'react';
import Translate from 'react-translate-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faMailBulk } from '@fortawesome/free-solid-svg-icons'

function Contact()
{
  return(
    <section>
      <div className="container tc p20 wow fadeIn" data-wow-duration="1s" data-wow-offset="150">
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-8">
            <ContactForm/>
          </div>
        </div>
      </div>
    </section>
  )
}

class ContactForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        mail: '',
        mesg: '',
        oksend: false
    };
    }
  
    handleChange = (event) => {
      const target = event.target;
      const value = target.value;
      const input = target.name;
  
      this.setState({
        [input]: value
      });
    }
  
    handleSubmit = (event) => {
      sendMail(this.state.mail,this.state.name,this.state.mesg);
      //document.getElementById('MsgSend').modal('toggle');

      this.setState({
        name:'',
        mail:'',
        mesg:'', 
        oksend: true
      })
      event.preventDefault();
    }
  
    render() {
      if (!this.state.oksend){
      return (
        <div className="white">
          <Translate content='contactform.title' component='h4'/>
          <br/>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="inp_name"><Translate content='contactform.t1'/></label>
              <input type="text" className="form-control" id="inp_name" placeholder="Escribe tu nombre" maxLength="20"
              value={this.state.name} onChange={this.handleChange} name="name" required/>
            </div>
            <div className="form-group">
              <label htmlFor="inp_mail"><Translate content='contactform.t2'/></label>
              <input type="email" className="form-control" id="inp_mail" aria-describedby="emailHelp" placeholder="Escribe tu correo"
              value={this.state.mail} onChange={this.handleChange} name="mail" required/>
              <small id="emailHelp"><Translate content='contactform.sc'/></small>
            </div>
            <div className="form-group">
              <label htmlFor="inp_text"><Translate content='contactform.t3'/></label>
              <textarea className="form-control" id="inp_text" placeholder="Escribe tu mensaje"
              value={this.state.mesg} onChange={this.handleChange} name="mesg" required/>
            </div>
            <p><a href="http://frenzygamesstd.com/docs/Lineamientos_Contrato.pdf" target="_blank" rel="noopener noreferrer">
              <Translate content='contactform.sl'/></a></p>
            <button type="submit" className="btn btn-primary">
              <FontAwesomeIcon icon={faPaperPlane}/><span>&nbsp;</span>
              <Translate content='contactform.send'/></button>
          </form>
        </div>
      );
      } else {
        return(
        <div className="wow fadeIn white">
          <p><FontAwesomeIcon icon={faMailBulk} size="5x"/></p>
          <Translate content='contactform.sended' component="h5"/>
          <Translate content='contactform.success' component="p"/>
        </div>
        )
      }
    }
  }
  
function sendMail(from,name,text) {
  var link = "mailto:frenzygames00@hotmail.com"
           + "?cc="+from
           + "&subject=" + escape(name)
           + "&body=" + escape(text)
  ;

  window.location.href = link;
}

  export default Contact;