import React from 'react';
import ProjectPreview from './ProjectPreview';
//import img_ph_256 from '../img/256x256.jpg';
import img_pr01 from '../img/projects/proyect_01.jpg';
import img_pr02 from '../img/projects/proyect_02.jpg';
import img_pr03 from '../img/projects/proyect_03.jpg';
import img_pr04 from '../img/projects/proyect_04.jpg';

function Projects(props)
{
    return(
        <section>
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-lg-6 col-xl-3 wow fadeIn" data-wow-duration="1s" data-wow-offset="200">
                        <ProjectPreview image={img_pr01} title="projects.p1"/>
                    </div>
                    <div className="col-lg-6 col-xl-3 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" data-wow-offset="200">
                        <ProjectPreview image={img_pr02}  title="projects.p2"/>
                    </div>
                    <div className="col-lg-6 col-xl-3 wow fadeIn" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="200">
                        <ProjectPreview image={img_pr03}  title="projects.p3"/>
                    </div>
                    <div className="col-lg-6 col-xl-3 wow fadeIn" data-wow-duration="1s" data-wow-delay="1.5s" data-wow-offset="200">
                        <ProjectPreview image={img_pr04}  title="projects.p4"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;