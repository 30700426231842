export default {
    menu:{
        m0:'Home',
        m1:'Services',
        m2:'Projects',
        m3:'Contact',
        m4:'Language'
    },
    title:"Frenzy Games Std",
    subtitles:{
        s1:'Professional work, servicio confiable',
        s2:'Tu proyecto al alcanze de todos',
        s3:'Un vistazo a todas las posibilidades',
        s4:'¿Cual es tu idea?'
    },
    description:'Con mas de 8 años de experiencias, sabemos que necesitas soluciones novedosas y eficientes. Tu proyecto es importante, no te conformes con sistemas fragiles o inseguros. Busca aquello que te de tranquilidad, no mas problemas.',
    services:{
        s1:'Web',
        s2:'Interactivos',
        s3:'Mobiles',
        sd1:'Aplicaciones web que se adaptan a cualquier dispositivo. Sin necesidad de tantos cambios o recargas de pagina, olvidaras que estas usando un explorador. Eficiente, confiable y rapido.',
        sd2:'Una idea crativa siempre hace la diferencia. Sorprende a tus usuarios y clientes con algo que realmente los marque. Juegos, aplicaciones interactivas y realidad virtual son tus mejores opciones.',
        sd3:'Tus servicios al alcanze de cualquier persona, sin importar cuando o donde. Una aplicacion movil duplica las posibilidades y el alcanze de tu proyecto. Al final ¿Quien no hace uso de un celular?'
    },
    projects:{
        p1:'Pagina informativa',
        p2:'Orders Quest',
        p3:'App de restaurant',
        p4:'Cosmic Hero VR'
    },
    contactform:{
        title:'Hablemos de tu proyecto, envia un mensaje resumido lo que buscas construir.',
        t1:'Name:',
        t2:'email:',
        t3:'Message:',
        sc:'Your email information won’t be shared with anyone',
        sl:'lineamientos de proyecto',
        send:'Send',
        sended:'Tu mensaje se enviara desde tu aplicación de correo preferida',
        success:'Resiviras una respuesta pronto, mantente atento a tu correo.'
    }
}
